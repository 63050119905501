import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { empty } from 'rxjs';
@Component({
  selector: 'app-chartjs',
  templateUrl: './chartjs.component.html',
  styleUrls: ['./chartjs.component.css']
})
export class ChartjsComponent implements OnInit {
  souks: any[] = [];
  selectedSouk: any;
  error: any;
  detailsu = 1; // Assuming initial state

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.fetchSouks();
  }

  fetchSouks(): void {
    this.http.get<any>('https://oualis.com/Platforme/api/souks').subscribe(
      (response) => {
        if (response && response.length > 0) {
          this.souks = response;
          console.log('Souks:', this.souks); // Logging to verify data in console
        } else {
          this.error = 'No Souk data found.';
        }
      },
      (error) => {
        this.error = 'Error fetching Souk data. Please try again.'; // Set error message
        console.error('Error fetching Souk data:', error);
      }
    );
  }

  convertOuiNon(value: number): string {
    return value === 1 ? 'Oui' : 'Non';
  }
  backup(): void {
    this.selectedSouk = null;
    if (this.detailsu == 1) {
      this.detailsu = 2;
    } else {
      this.detailsu = 1;
    }
  }
}
