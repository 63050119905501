import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.css']
})
export class InboxComponent implements OnInit {

  users: any[] = [];
  loading: boolean = false;
  error: string | null = null;
  id:any;
  constructor(private http: HttpClient) {}
  ngOnInit(): void {
    this.fetchUsers();
    this.id = localStorage.getItem('id');
  }
  users45:any;
   fetchUsers(): void {
     this.http.get<any[]>('https://oualis.com/Platforme/api/equipes').subscribe(
      (response) => {
        this.users45 = response;

         console.log('Users:', this.users); // Logging to verify data in console
      },
      (error) => {
        this.error = 'Error fetching users. Please try again.'; // Set error message
         console.error('Error fetching users:', error);
      }
    );
  }

  equipe:any;
  equipeaz(): void {
    this.http.get<any[]>('https://oualis.com/Platforme/api/equipes/user/'+this.userid).subscribe(
     (response) => {
       this.equipe = response;

        console.log('equipe:', this.equipe); // Logging to verify data in console
     },
     (error) => {
       this.error = 'Error fetching users. Please try again.'; // Set error message
        console.error('Error fetching equipe:', error);
     }
   );
 }
 Voiture:any;
 immatriculation:any;
 km:any;
 model:any;
 marque:any;
 Voitureaz(): void {
  this.http.get<any>('https://oualis.com/Platforme/api/voitures/user/' + this.userid).subscribe(
    (response) => {
      if (response && response.length > 0) {
        this.Voiture = response[0];
        this.immatriculation = this.Voiture.immatriculation;
        this.km = this.Voiture.km;
        this.model = this.Voiture.model;
        this.marque = this.Voiture.marque;

        console.log('Voiture:', this.Voiture); // Logging to verify data in console
      } else {
        this.error = 'No Voiture data found.';
      }
    },
    (error) => {
      this.error = 'Error fetching Voiture. Please try again.'; // Set error message
      console.error('Error fetching Voiture:', error);
    }
  );
}

  checkUncheckAll(event) {
     var checkboxes = document.getElementsByTagName('input');
     if (event.target.checked) {
         for (var i = 0; i < checkboxes.length; i++) {
             if (checkboxes[i].type == 'checkbox') {
                 checkboxes[i].checked = true;
             }
         }
     } else {
         for (var i = 0; i < checkboxes.length; i++) {
             // console.log(i)
             if (checkboxes[i].type == 'checkbox') {
                 checkboxes[i].checked = false;
             }
         }
     }
 }
detailsu:any = 1;
userid:any;
change(id:any){
  this.userid = id;
  this.equipeaz();
  this.Voitureaz();
  if(this.detailsu == 1) {
    this.detailsu = 2;
  }
  else {
    this.detailsu = 1;
  }
}
backup(): void {
  this.immatriculation = '';
  this.km = '';
  this.model = '';
  this.marque = '';

  if (this.detailsu == 1) {
    this.detailsu = 2;
  } else {
    this.detailsu = 1;
  }
}
}
