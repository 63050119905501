import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { empty } from 'rxjs';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  apiUrl = 'https://oualis.com/Platforme/api/';
email: string = '';
phone_number: string = '';
password: string = '';
rememberMe: boolean = false;
name: any;
constructor(private http: HttpClient, private router: Router) {}
ngOnInit(): void {
}
role:any;
onSubmit(): void {
  const body = {
    name: this.name,
    phone_number: this.phone_number,
    password: this.password,
    Role: this.role
  };

  this.http.post<any>(`${this.apiUrl}user/create`, body)
    .subscribe(
      response => {
        // Handle successful login, e.g., store token in local storage
        alert('Merci Superviseur Ajouter avec succès');
        window.location.reload(); // Reload the page  // Reset password to an empty string
      },
      error => {
        // Handle error, e.g., show error message
        console.error('Login error:', error);
        alert('Vérifier les informations ou numéro déjà existant');
      }
    );
}
resetForm(): void {
  this.name = '';
  this.phone_number = '';
  this.password = '';
}
}
