<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Apex Chart</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Mixed chart</a></li>
                </ol>
            </div>
        </div>
        <!-- row -->
        <div class="row">
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Line Column</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-mixed-line-column></app-mixed-line-column>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Multiple Y-Axis</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-mixed-multiple-y-axis></app-mixed-multiple-y-axis>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Line & Area</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-mixed-line-area></app-mixed-line-area>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Line Column Area</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-mixed-line-column-area></app-mixed-line-column-area>
                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->