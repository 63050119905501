
<!--**********************************
	Content body start
***********************************-->
<div class="content-body">
	<div class="container-fluid">
		<div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                  <h4>Bonjour, bon retour !</h4>
                                 </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Voiture</a></li>
                 </ol>
            </div>
        </div>
		<div class="row">
			<div class="col-xl-12">
				<div class="card">
					<div class="card-body">
						<div class="row">
					 	<div class="col-lg-12 col-md-12 order-md-1">
								<h4 class="mb-3">Ajouter Voiture</h4>
                <div class="mb-3">
                  <label for="country">Sélectionner Superviseur</label>
                  <select class="d-block w-100 default-select form-control" id="country" required="" [(ngModel)]="iduser">
                    <option value="">Choose...</option>
                    <option *ngFor="let item of users45" [value]="item.id">{{item.name}}</option>
                  </select>
                </div>
 									<div class="row">

										<!-- <div class="col-md-6 mb-3"> -->
                      <!-- <label for="lastName"><label>
                        <input type="text" class="form-control" id="lastName" placeholder="" value="" required=""
                        [(ngModel)]="immatriculation">
                      </div> -->
                      <div class="col-md-6 mb-3">
                        <label for="lastName">Immatriculation</label>
                        <input type="text" class="form-control" id="lastName" placeholder="" value="" required="" [(ngModel)]="immatriculation">
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="lastName">KM</label>
                        <input type="text" class="form-control" id="lastName" placeholder="" value="" required="" [(ngModel)]="km">
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="lastName">Model</label>
                        <input type="text" class="form-control" id="lastName" placeholder="" value="" required="" [(ngModel)]="model">
                      </div>
                    <div class="col-md-6 mb-3">
											<label for="lastName">Marque</label>
											<input type="text" class="form-control" id="lastName" placeholder="" value="" required="" [(ngModel)]="marque">
										</div>

									</div>


									<!-- <div class="mb-3">
										<label for="email">Email <span
										class="text-muted">(Optional)</span></label>
										<input type="email" class="form-control" id="email" placeholder="you@example.com">
										<div class="invalid-feedback">
											Please enter a valid email address for shipping updates.
										</div>
									</div>

									<div class="mb-3">
										<label for="address">Address</label>
										<input type="text" class="form-control" id="address" placeholder="1234 Main St" required="">
										<div class="invalid-feedback">
											Please enter your shipping address.
										</div>
									</div>

									<div class="mb-3">
										<label for="address2">Address 2 <span
										class="text-muted">(Optional)</span></label>
										<input type="text" class="form-control" id="address2" placeholder="Apartment or suite">
									</div>

									<div class="row">
										<div class="col-lg-5 col-md-12 mb-3">
											<label for="country">Country</label>
											<select class="d-block w-100 default-select form-control" id="country" required="">
												<option value="">Choose...</option>
												<option>United States</option>
											</select>
											<div class="invalid-feedback">
												Please select a valid country.
											</div>
										</div>
										<div class="col-lg-4 col-md-12 mb-3">
											<label for="state">State</label>
											<select class="d-block default-select form-control selectpicker w-100" id="state" required="">
												<option value="">Choose...</option>
												<option>California</option>
											</select>
											<div class="invalid-feedback">
												Please provide a valid state.
											</div>
										</div>
										<div class="col-lg-3 col-md-12 mb-3">
											<label for="zip">Zip</label>
											<input type="text" class="form-control" id="zip" placeholder="" required="">
											<div class="invalid-feedback">
												Zip code required.
											</div>
										</div>
									</div> -->
									<!-- <hr class="mb-4">
									<div class="custom-control custom-checkbox mb-2">
										<input type="checkbox" class="custom-control-input" id="same-address">
										<label class="custom-control-label" for="same-address">Shipping address
											is
											the same as
										my billing address</label>
									</div>
									<div class="custom-control custom-checkbox mb-2">
										<input type="checkbox" class="custom-control-input" id="save-info">
										<label class="custom-control-label" for="save-info">Save this
											information
											for next
										time</label>
									</div>
									<hr class="mb-4">

									<h4 class="mb-3">Payment</h4>

									<div class="d-block my-3">
										<div class="custom-control custom-radio mb-2">
											<input id="credit" name="paymentMethod" type="radio" class="custom-control-input" checked="" required="">
											<label class="custom-control-label" for="credit">Credit card</label>
										</div>
										<div class="custom-control custom-radio mb-2">
											<input id="debit" name="paymentMethod" type="radio" class="custom-control-input" required="">
											<label class="custom-control-label" for="debit">Debit card</label>
										</div>
										<div class="custom-control custom-radio mb-2">
											<input id="paypal" name="paymentMethod" type="radio" class="custom-control-input" required="">
											<label class="custom-control-label" for="paypal">Paypal</label>
										</div>
									</div>
									<div class="row">
										<div class="col-md-6 mb-3">
											<label for="cc-name">Name on card</label>
											<input type="text" class="form-control" id="cc-name" placeholder="" required="">
											<small class="text-muted">Full name as displayed on card</small>
											<div class="invalid-feedback">
												Name on card is required
											</div>
										</div>
										<div class="col-md-6 mb-3">
											<label for="cc-number">Credit card number</label>
											<input type="text" class="form-control" id="cc-number" placeholder="" required="">
											<div class="invalid-feedback">
												Credit card number is required
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-3 mb-3">
											<label for="cc-expiration">Expiration</label>
											<input type="text" class="form-control" id="cc-expiration" placeholder="" required="">
											<div class="invalid-feedback">
												Expiration date required
											</div>
										</div>
										<div class="col-md-3 mb-3">
											<label for="cc-expiration">CVV</label>
											<input type="text" class="form-control" id="cc-cvv" placeholder="" required="">
											<div class="invalid-feedback">
												Security code required
											</div>
										</div>
									</div> -->
									<hr class="mb-4">
									<button class="btn btn-primary btn-lg btn-block" type="submit" (click)="onSubmit()">Ajouter</button>
 							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!--**********************************
	Content body end
***********************************-->
