import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  apiUrl = 'https://oualis.com/Platforme/api/';
  email: string = '';
  password: string = '';
  rememberMe: boolean = false;
  constructor(private http: HttpClient, private router: Router) {}
  ngOnInit(): void {
  }
  onSubmit(): void {
    const body = { email: this.email, password: this.password };

    this.http.post<any>(`${this.apiUrl}loginADMIN`, body)
      .subscribe(
        response => {
          // Handle successful login, e.g., store token in local storage
          console.log('Login successful:', response);
          localStorage.setItem('email',response.email);
          localStorage.setItem('password',response.password);
          localStorage.setItem('role',response.role  );

          this.router.navigate(['/admin/index']);
          // Optionally, redirect to another page
        },
        error => {
          alert('Vérifier Votre Informations')
          // Handle error, e.g., show error message
          console.error('Login error:', error);
        }
      );
  }
}
