<div class="card overflow-hidden">
    <div class="card-header align-items-start border-0 pb-0">
        <div class="mr-auto">
            <p class="mb-2 fs-13"><i class="fa fa-caret-up scale5 mr-2 text-success" aria-hidden="true"></i>Nombre Seperviseur :</p>
            <h2 class="text-black mb-0 font-w600"> {{usercount}}</h2>
            <br>
        </div>

    </div>
    <div class="card-body p-0">
        <!-- <canvas id="widgetChart" class="max-h80 mt-auto"></canvas> -->

        <div class="chart-wrapper">

</div>



    </div>
</div>
