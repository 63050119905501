<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
      <div class="row page-titles mx-0">
          <div class="col-sm-6 p-md-0">
              <div class="welcome-text">
                  <h4>Bonjour, bon retour !</h4>
              </div>
          </div>
          <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
              <ol class="breadcrumb">
               </ol>
          </div>
      </div>
      <div class="row">
          <div class="col-lg-12">
              <div class="card">
                  <div class="card-body">
                      <div class="table-responsive">
                          <table class="table table-responsive-lg mb-0 table-striped">
                              <thead>
                                  <tr>
                                      <th>ID</th>
                                      <th>Region</th>
                                      <th>Province</th>
                                      <th>Cercle</th>
                                      <th>Commune</th>
                                      <th>Centre</th>
                                      <th>Milieu</th>
                                      <th>Identifiant du Souk</th>
                                      <th>Nom du Souk</th>
                                      <th>Opérationnel</th>
                                      <th>Pourquoi</th>
                                      <th>Jour du Souk</th>
                                      <th>Activité Dominante 1</th>
                                      <th>Activité Dominante 2</th>
                                      <th>Activité Dominante 3</th>
                                      <th>Eau Potable</th>
                                      <th>Electricité</th>
                                      <th>Accès Route/Piste</th>
                                      <th>Parking</th>
                                      <th>Poste</th>
                                      <th>Internet</th>
                                      <th>CB Première</th>
                                      <th>CB Deuxième</th>
                                      <th>CB Troisième</th>
                                      <th>CB Quatrième</th>
                                      <th>CB Cinquième</th>
                                      <th>Code Géographique</th>
                                      <th>Souk Hebdomadaire</th>
                                      <th>Plan de Développement</th>
                                      <th>Maison Communale</th>
                                      <th>Caidat</th>
                                      <th>Gendarmerie Royale</th>
                                      <th>Agence Crédit Agricole</th>
                                      <th>Bureau Poste</th>
                                      <th>Centre Travaux Agricoles</th>
                                      <th>Dispensaire Rural</th>
                                      <th>Centre Santé Communal</th>
                                      <th>Infirmier Privé</th>
                                      <th>Pharmacie</th>
                                      <th>Mécanicien Dentiste</th>
                                      <th>Foyer Féminin</th>
                                      <th>Maison Jeunes</th>
                                      <th>École Coranique/Msid</th>
                                      <th>École Primaire Autonome</th>
                                      <th>École Primaire Centrale</th>
                                      <th>École Primaire Satellite</th>
                                      <th>Collège</th>
                                      <th>Lycee</th>
                                      <th>Établissement Formation Professionnelle</th>
                                      <th>Grand Taxi</th>
                                      <th>Autocar</th>
                                      <th>Train</th>
                                      <th>Autre Moyen</th>
                                      <th>Réseau Électricité</th>
                                      <th>Réseau Eau Potable</th>
                                      <th>Réseau Assainissement</th>
                                      <th>Image</th>
                                      <th>Description</th>
                                  </tr>
                              </thead>
                              <tbody id="customers">
                                  <tr class="btn-reveal-trigger" *ngFor="let souk of souks">
                                      <td class="py-2 pl-5 wspace-no">{{souk.id}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{souk.region}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{souk.province}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{souk.cercle}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{souk.commune}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{souk.centre}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{souk.milieu}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{souk.identifiant_du_souk}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{souk.nom_du_souk}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.operationnel)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{souk.pourquoi}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{souk.jour_du_souk}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{souk.activite_dominante_1}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{souk.activite_dominante_2}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{souk.activite_dominante_3}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.eau_potable)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.electricite)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.acces_route_piste)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.parking)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.poste)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.internet)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.cbpremiere)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.cbdeuxieme)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.cbtroisieme)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.cbquatrieme)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.cbcinquieme)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{souk.code_geographique}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.souk_hebdomadaire)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.plan_de_developpement)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.maison_communale)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.caidat)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.gendarmerie_royale)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.agence_credit_agricole)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.bureau_poste)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.centre_travaux_agricoles)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.dispensaire_rural)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.centre_sante_communal)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.infirmier_prive)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.pharmacie)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.mecanicien_dentiste)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.foyer_feminin)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.maison_jeunes)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.ecole_coranique_msid)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.ecole_primaire_autonome)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.ecole_primaire_centrale)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.ecole_primaire_satellite)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.college)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.lycee)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.etablissement_formation_professionnelle)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.grand_taxi)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.autocar)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.train)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.autre_moyen)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.reseau_electricite)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.reseau_eau_potable)}}</td>
                                      <td class="py-2 pl-5 wspace-no">{{convertOuiNon(souk.reseau_assainissement)}}</td>
                                      <td class="py-2 pl-5 wspace-no"> <a [href]="souk.image" target="_blank">{{souk.image}}</a></td>
                                      <td class="py-2 pl-5 wspace-no">{{souk.description}}</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<!--**********************************
  Content body end
***********************************-->
