<div class="card">
    <div class="card-header pb-2 d-block d-sm-flex flex-wrap border-0">
        <div class="mb-3">
            <h4 class="fs-20 text-black">Wallet Activity</h4>
            <p class="mb-0 fs-13">Lorem ipsum dolor sit amet, consectetur</p>
        </div>
        <div class="card-action card-tabs mb-3">

            <ul class="nav nav-tabs" role="tablist" ngbNav #nav="ngbNav" >
                <li class="nav-item" ngbNavItem="Monthly">
                    <a class="nav-link" data-toggle="tab" href="#Monthly" role="tab" aria-selected="false" ngbNavLink>
                        Monthly
                    </a>
                    <ng-template ngbNavContent>
                        <div class="table-responsive">
                            <table class="table shadow-hover short-one card-table border-no">
                                <tbody>
                                    <tr>
                                        <td>
                                            <span class="activity-icon">
                                                <svg width="15" height="27" viewBox="0 0 15 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.81299 21.393L8.81299 2.74998C8.81299 1.95606 8.16942 1.31248 7.37549 1.31248C6.58157 1.31248 5.93799 1.95606 5.93799 2.74998L5.93799 21.393L3.5194 18.9627L3.51938 18.9627C2.95934 18.4 2.0492 18.3978 1.48649 18.9578C0.923597 19.518 0.921712 20.4282 1.48158 20.9908L1.83599 20.6381L1.48158 20.9908L6.35659 25.8894L6.36177 25.8946L6.36345 25.8962C6.92422 26.4522 7.82863 26.4503 8.38748 25.8962L8.38912 25.8947L8.3944 25.8894L13.2694 20.9907L13.2694 20.9907C13.8294 20.428 13.8273 19.5179 13.2645 18.9578C12.7018 18.3977 11.7917 18.3999 11.2316 18.9626L11.2316 18.9626L8.81299 21.393Z" fill="#61C277" stroke="#61C277"/>
                                                </svg>
                                            </span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">Withdraw</span>
                                        </td>
                                        <td>
                                            <span class="text-black">06:24:45 AM</span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">+$5,553</span>
                                        </td>
                                        <td><a class="btn-link text-light float-right" href="javascript:void(0);">Pending</a></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="activity-icon">
                                                <svg width="15" height="27" viewBox="0 0 15 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.9375 6.232L5.9375 24.875C5.9375 25.6689 6.58107 26.3125 7.375 26.3125C8.16892 26.3125 8.8125 25.6689 8.8125 24.875L8.8125 6.23202L11.2311 8.66232L11.2311 8.66234C11.7911 9.22504 12.7013 9.2272 13.264 8.66718C13.8269 8.10702 13.8288 7.19681 13.2689 6.63421L12.9145 6.98691L13.2689 6.63421L8.3939 1.73558L8.38872 1.73037L8.38704 1.72878C7.82626 1.17281 6.92186 1.17469 6.36301 1.72877L6.36136 1.73033L6.35609 1.73563L1.48109 6.63426L1.48108 6.63426C0.921124 7.19695 0.9232 8.10709 1.48597 8.6672C2.04868 9.22725 2.95884 9.22509 3.51889 8.66239L3.51891 8.66236L5.9375 6.232Z" fill="#9e3383" stroke="#9e3383"/>
                                                </svg>
                                            </span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">Topup</span>
                                        </td>
                                        <td>
                                            <span class="text-black">06:24:45 AM</span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">+$5,553</span>
                                        </td>
                                        <td>
                                            <a class="btn-link text-success float-right" href="javascript:void(0);">Completed</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="activity-icon">
                                                <svg width="15" height="27" viewBox="0 0 15 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.81299 21.393L8.81299 2.74998C8.81299 1.95606 8.16942 1.31248 7.37549 1.31248C6.58157 1.31248 5.93799 1.95606 5.93799 2.74998L5.93799 21.393L3.5194 18.9627L3.51938 18.9627C2.95934 18.4 2.0492 18.3978 1.48649 18.9578C0.923597 19.518 0.921712 20.4282 1.48158 20.9908L1.83599 20.6381L1.48158 20.9908L6.35659 25.8894L6.36177 25.8946L6.36345 25.8962C6.92422 26.4522 7.82863 26.4503 8.38748 25.8962L8.38912 25.8947L8.3944 25.8894L13.2694 20.9907L13.2694 20.9907C13.8294 20.428 13.8273 19.5179 13.2645 18.9578C12.7018 18.3977 11.7917 18.3999 11.2316 18.9626L11.2316 18.9626L8.81299 21.393Z" fill="#61C277" stroke="#61C277"/>
                                                </svg>
                                            </span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">Wihtdraw</span>
                                        </td>
                                        <td>
                                            <span class="text-black">06:24:45 AM</span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">-$912</span>
                                        </td>
                                        <td>
                                            <a class="btn-link text-danger float-right" href="javascript:void(0);">Canceled</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="activity-icon">
                                                <svg width="15" height="27" viewBox="0 0 15 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.9375 6.232L5.9375 24.875C5.9375 25.6689 6.58107 26.3125 7.375 26.3125C8.16892 26.3125 8.8125 25.6689 8.8125 24.875L8.8125 6.23202L11.2311 8.66232L11.2311 8.66234C11.7911 9.22504 12.7013 9.2272 13.264 8.66718C13.8269 8.10702 13.8288 7.19681 13.2689 6.63421L12.9145 6.98691L13.2689 6.63421L8.3939 1.73558L8.38872 1.73037L8.38704 1.72878C7.82626 1.17281 6.92186 1.17469 6.36301 1.72877L6.36136 1.73033L6.35609 1.73563L1.48109 6.63426L1.48108 6.63426C0.921124 7.19695 0.9232 8.10709 1.48597 8.6672C2.04868 9.22725 2.95884 9.22509 3.51889 8.66239L3.51891 8.66236L5.9375 6.232Z" fill="#9e3383" stroke="#9e3383"/>
                                                </svg>
                                            </span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">Topup</span>
                                        </td>
                                        <td>
                                            <span class="text-black">06:24:45 AM</span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">+$7,762</span>
                                        </td>
                                        <td>
                                            <a class="btn-link text-success float-right" href="javascript:void(0);">Completed</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="activity-icon">
                                                <svg width="15" height="27" viewBox="0 0 15 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.9375 6.232L5.9375 24.875C5.9375 25.6689 6.58107 26.3125 7.375 26.3125C8.16892 26.3125 8.8125 25.6689 8.8125 24.875L8.8125 6.23202L11.2311 8.66232L11.2311 8.66234C11.7911 9.22504 12.7013 9.2272 13.264 8.66718C13.8269 8.10702 13.8288 7.19681 13.2689 6.63421L12.9145 6.98691L13.2689 6.63421L8.3939 1.73558L8.38872 1.73037L8.38704 1.72878C7.82626 1.17281 6.92186 1.17469 6.36301 1.72877L6.36136 1.73033L6.35609 1.73563L1.48109 6.63426L1.48108 6.63426C0.921124 7.19695 0.9232 8.10709 1.48597 8.6672C2.04868 9.22725 2.95884 9.22509 3.51889 8.66239L3.51891 8.66236L5.9375 6.232Z" fill="#9e3383" stroke="#9e3383"/>
                                                </svg>
                                            </span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">Topup</span>
                                        </td>
                                        <td>
                                            <span class="text-black">06:24:45 AM</span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">+$5,553</span>
                                        </td>
                                        <td>
                                            <a class="btn-link text-success float-right" href="javascript:void(0);">Completed</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="activity-icon">
                                                <svg width="15" height="27" viewBox="0 0 15 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.81299 21.393L8.81299 2.74998C8.81299 1.95606 8.16942 1.31248 7.37549 1.31248C6.58157 1.31248 5.93799 1.95606 5.93799 2.74998L5.93799 21.393L3.5194 18.9627L3.51938 18.9627C2.95934 18.4 2.0492 18.3978 1.48649 18.9578C0.923597 19.518 0.921712 20.4282 1.48158 20.9908L1.83599 20.6381L1.48158 20.9908L6.35659 25.8894L6.36177 25.8946L6.36345 25.8962C6.92422 26.4522 7.82863 26.4503 8.38748 25.8962L8.38912 25.8947L8.3944 25.8894L13.2694 20.9907L13.2694 20.9907C13.8294 20.428 13.8273 19.5179 13.2645 18.9578C12.7018 18.3977 11.7917 18.3999 11.2316 18.9626L11.2316 18.9626L8.81299 21.393Z" fill="#61C277" stroke="#61C277"/>
                                                </svg>
                                            </span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">Wihtdraw</span>
                                        </td>
                                        <td>
                                            <span class="text-black">06:24:45 AM</span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">-$912</span>
                                        </td>
                                        <td>
                                            <a class="btn-link text-danger float-right" href="javascript:void(0);">Canceled</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-template>
                </li>
                <li class="nav-item" ngbNavItem="Weekly">
                    <a class="nav-link" data-toggle="tab" href="#Weekly" role="tab" aria-selected="false" ngbNavLink>
                        Weekly
                    </a>
                    <ng-template ngbNavContent>
                        <div class="table-responsive">
                            <table class="table shadow-hover short-one  card-table  border-no">
                                <tbody>
                                    <tr>
                                        <td>
                                            <span class="activity-icon">
                                                <svg width="15" height="27" viewBox="0 0 15 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.81299 21.393L8.81299 2.74998C8.81299 1.95606 8.16942 1.31248 7.37549 1.31248C6.58157 1.31248 5.93799 1.95606 5.93799 2.74998L5.93799 21.393L3.5194 18.9627L3.51938 18.9627C2.95934 18.4 2.0492 18.3978 1.48649 18.9578C0.923597 19.518 0.921712 20.4282 1.48158 20.9908L1.83599 20.6381L1.48158 20.9908L6.35659 25.8894L6.36177 25.8946L6.36345 25.8962C6.92422 26.4522 7.82863 26.4503 8.38748 25.8962L8.38912 25.8947L8.3944 25.8894L13.2694 20.9907L13.2694 20.9907C13.8294 20.428 13.8273 19.5179 13.2645 18.9578C12.7018 18.3977 11.7917 18.3999 11.2316 18.9626L11.2316 18.9626L8.81299 21.393Z" fill="#61C277" stroke="#61C277"/>
                                                </svg>
                                            </span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">Bitcoin</span>
                                        </td>
                                        <td>
                                            <span class="text-black">06:24:45 AM</span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">+$5,553</span>
                                        </td>
                                        <td><a class="btn-link text-success float-right" href="#">Completed</a></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="activity-icon">
                                                <svg width="15" height="27" viewBox="0 0 15 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.81299 21.393L8.81299 2.74998C8.81299 1.95606 8.16942 1.31248 7.37549 1.31248C6.58157 1.31248 5.93799 1.95606 5.93799 2.74998L5.93799 21.393L3.5194 18.9627L3.51938 18.9627C2.95934 18.4 2.0492 18.3978 1.48649 18.9578C0.923597 19.518 0.921712 20.4282 1.48158 20.9908L1.83599 20.6381L1.48158 20.9908L6.35659 25.8894L6.36177 25.8946L6.36345 25.8962C6.92422 26.4522 7.82863 26.4503 8.38748 25.8962L8.38912 25.8947L8.3944 25.8894L13.2694 20.9907L13.2694 20.9907C13.8294 20.428 13.8273 19.5179 13.2645 18.9578C12.7018 18.3977 11.7917 18.3999 11.2316 18.9626L11.2316 18.9626L8.81299 21.393Z" fill="#61C277" stroke="#61C277"/>
                                                </svg>
                                            </span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">Ethereum</span>
                                        </td>
                                        <td>
                                            <span class="text-black">06:24:45 AM</span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">-$542</span>
                                        </td>
                                        <td>
                                            <a class="btn-link text-dark float-right" href="#">Pending</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="activity-icon">
                                                <svg width="15" height="27" viewBox="0 0 15 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.81299 21.393L8.81299 2.74998C8.81299 1.95606 8.16942 1.31248 7.37549 1.31248C6.58157 1.31248 5.93799 1.95606 5.93799 2.74998L5.93799 21.393L3.5194 18.9627L3.51938 18.9627C2.95934 18.4 2.0492 18.3978 1.48649 18.9578C0.923597 19.518 0.921712 20.4282 1.48158 20.9908L1.83599 20.6381L1.48158 20.9908L6.35659 25.8894L6.36177 25.8946L6.36345 25.8962C6.92422 26.4522 7.82863 26.4503 8.38748 25.8962L8.38912 25.8947L8.3944 25.8894L13.2694 20.9907L13.2694 20.9907C13.8294 20.428 13.8273 19.5179 13.2645 18.9578C12.7018 18.3977 11.7917 18.3999 11.2316 18.9626L11.2316 18.9626L8.81299 21.393Z" fill="#61C277" stroke="#61C277"/>
                                                </svg>
                                            </span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">Ripple</span>
                                        </td>
                                        <td>
                                            <span class="text-black">06:24:45 AM</span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">-$912</span>
                                        </td>
                                        <td>
                                            <a class="btn-link text-danger float-right" href="#">Canceled</a>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <span class="activity-icon">
                                                <svg width="15" height="27" viewBox="0 0 15 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.81299 21.393L8.81299 2.74998C8.81299 1.95606 8.16942 1.31248 7.37549 1.31248C6.58157 1.31248 5.93799 1.95606 5.93799 2.74998L5.93799 21.393L3.5194 18.9627L3.51938 18.9627C2.95934 18.4 2.0492 18.3978 1.48649 18.9578C0.923597 19.518 0.921712 20.4282 1.48158 20.9908L1.83599 20.6381L1.48158 20.9908L6.35659 25.8894L6.36177 25.8946L6.36345 25.8962C6.92422 26.4522 7.82863 26.4503 8.38748 25.8962L8.38912 25.8947L8.3944 25.8894L13.2694 20.9907L13.2694 20.9907C13.8294 20.428 13.8273 19.5179 13.2645 18.9578C12.7018 18.3977 11.7917 18.3999 11.2316 18.9626L11.2316 18.9626L8.81299 21.393Z" fill="#61C277" stroke="#61C277"/>
                                                </svg>
                                            </span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">Ripple</span>
                                        </td>
                                        <td>
                                            <span class="text-black">06:24:45 AM</span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">-$912</span>
                                        </td>
                                        <td>
                                            <a class="btn-link text-danger float-right" href="#">Canceled</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-template>
                </li>
                <li class="nav-item" ngbNavItem="Today">
                    <a class="nav-link" data-toggle="tab" href="#Today" role="tab" aria-selected="false" ngbNavLink>
                        Today
                    </a>
                    <ng-template ngbNavContent>
                        <div class="table-responsive">
                            <table class="table shadow-hover short-one card-table  border-no">
                                <tbody>
                                    <tr>
                                        <td>
                                            <span class="activity-icon">
                                                <svg width="15" height="27" viewBox="0 0 15 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.81299 21.393L8.81299 2.74998C8.81299 1.95606 8.16942 1.31248 7.37549 1.31248C6.58157 1.31248 5.93799 1.95606 5.93799 2.74998L5.93799 21.393L3.5194 18.9627L3.51938 18.9627C2.95934 18.4 2.0492 18.3978 1.48649 18.9578C0.923597 19.518 0.921712 20.4282 1.48158 20.9908L1.83599 20.6381L1.48158 20.9908L6.35659 25.8894L6.36177 25.8946L6.36345 25.8962C6.92422 26.4522 7.82863 26.4503 8.38748 25.8962L8.38912 25.8947L8.3944 25.8894L13.2694 20.9907L13.2694 20.9907C13.8294 20.428 13.8273 19.5179 13.2645 18.9578C12.7018 18.3977 11.7917 18.3999 11.2316 18.9626L11.2316 18.9626L8.81299 21.393Z" fill="#61C277" stroke="#61C277"/>
                                                </svg>
                                            </span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">Bitcoin</span>
                                        </td>
                                        <td>
                                            <span class="text-black">06:24:45 AM</span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">+$5,553</span>
                                        </td>
                                        <td><a class="btn-link text-success float-right" href="#">Completed</a></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="activity-icon">
                                                <svg width="15" height="27" viewBox="0 0 15 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.81299 21.393L8.81299 2.74998C8.81299 1.95606 8.16942 1.31248 7.37549 1.31248C6.58157 1.31248 5.93799 1.95606 5.93799 2.74998L5.93799 21.393L3.5194 18.9627L3.51938 18.9627C2.95934 18.4 2.0492 18.3978 1.48649 18.9578C0.923597 19.518 0.921712 20.4282 1.48158 20.9908L1.83599 20.6381L1.48158 20.9908L6.35659 25.8894L6.36177 25.8946L6.36345 25.8962C6.92422 26.4522 7.82863 26.4503 8.38748 25.8962L8.38912 25.8947L8.3944 25.8894L13.2694 20.9907L13.2694 20.9907C13.8294 20.428 13.8273 19.5179 13.2645 18.9578C12.7018 18.3977 11.7917 18.3999 11.2316 18.9626L11.2316 18.9626L8.81299 21.393Z" fill="#61C277" stroke="#61C277"/>
                                                </svg>
                                            </span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">Ethereum</span>
                                        </td>
                                        <td>
                                            <span class="text-black">06:24:45 AM</span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">-$542</span>
                                        </td>
                                        <td>
                                            <a class="btn-link text-dark float-right" href="#">Pending</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="activity-icon">
                                                <svg width="15" height="27" viewBox="0 0 15 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.81299 21.393L8.81299 2.74998C8.81299 1.95606 8.16942 1.31248 7.37549 1.31248C6.58157 1.31248 5.93799 1.95606 5.93799 2.74998L5.93799 21.393L3.5194 18.9627L3.51938 18.9627C2.95934 18.4 2.0492 18.3978 1.48649 18.9578C0.923597 19.518 0.921712 20.4282 1.48158 20.9908L1.83599 20.6381L1.48158 20.9908L6.35659 25.8894L6.36177 25.8946L6.36345 25.8962C6.92422 26.4522 7.82863 26.4503 8.38748 25.8962L8.38912 25.8947L8.3944 25.8894L13.2694 20.9907L13.2694 20.9907C13.8294 20.428 13.8273 19.5179 13.2645 18.9578C12.7018 18.3977 11.7917 18.3999 11.2316 18.9626L11.2316 18.9626L8.81299 21.393Z" fill="#61C277" stroke="#61C277"/>
                                                </svg>
                                            </span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">Ripple</span>
                                        </td>
                                        <td>
                                            <span class="text-black">06:24:45 AM</span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">-$912</span>
                                        </td>
                                        <td>
                                            <a class="btn-link text-danger float-right" href="#">Canceled</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="activity-icon">
                                                <svg width="15" height="27" viewBox="0 0 15 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.81299 21.393L8.81299 2.74998C8.81299 1.95606 8.16942 1.31248 7.37549 1.31248C6.58157 1.31248 5.93799 1.95606 5.93799 2.74998L5.93799 21.393L3.5194 18.9627L3.51938 18.9627C2.95934 18.4 2.0492 18.3978 1.48649 18.9578C0.923597 19.518 0.921712 20.4282 1.48158 20.9908L1.83599 20.6381L1.48158 20.9908L6.35659 25.8894L6.36177 25.8946L6.36345 25.8962C6.92422 26.4522 7.82863 26.4503 8.38748 25.8962L8.38912 25.8947L8.3944 25.8894L13.2694 20.9907L13.2694 20.9907C13.8294 20.428 13.8273 19.5179 13.2645 18.9578C12.7018 18.3977 11.7917 18.3999 11.2316 18.9626L11.2316 18.9626L8.81299 21.393Z" fill="#61C277" stroke="#61C277"/>
                                                </svg>
                                            </span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">Litecoin</span>
                                        </td>
                                        <td>
                                            <span class="text-black">06:24:45 AM</span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">+$7,762</span>
                                        </td>
                                        <td>
                                            <a class="btn-link text-success float-right" href="#">Completed</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="activity-icon">
                                                <svg width="15" height="27" viewBox="0 0 15 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.81299 21.393L8.81299 2.74998C8.81299 1.95606 8.16942 1.31248 7.37549 1.31248C6.58157 1.31248 5.93799 1.95606 5.93799 2.74998L5.93799 21.393L3.5194 18.9627L3.51938 18.9627C2.95934 18.4 2.0492 18.3978 1.48649 18.9578C0.923597 19.518 0.921712 20.4282 1.48158 20.9908L1.83599 20.6381L1.48158 20.9908L6.35659 25.8894L6.36177 25.8946L6.36345 25.8962C6.92422 26.4522 7.82863 26.4503 8.38748 25.8962L8.38912 25.8947L8.3944 25.8894L13.2694 20.9907L13.2694 20.9907C13.8294 20.428 13.8273 19.5179 13.2645 18.9578C12.7018 18.3977 11.7917 18.3999 11.2316 18.9626L11.2316 18.9626L8.81299 21.393Z" fill="#61C277" stroke="#61C277"/>
                                                </svg>
                                            </span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">Bitcoin</span>
                                        </td>
                                        <td>
                                            <span class="text-black">06:24:45 AM</span>
                                        </td>
                                        <td>
                                            <span class="font-w600 text-black">+$5,553</span>
                                        </td>
                                        <td>
                                            <a class="btn-link text-success float-right" href="javascript:void(0);">Completed</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-template>
                </li>
            </ul>
        </div>
    </div>
<div class="card-body p-0">
    <div [ngbNavOutlet]="nav" class=""></div>
</div>
</div>
