<!--**********************************
    Footer start
***********************************-->
<div class="footer">
    <div class="copyright">
        <p>Copyright © <a href="http://oualis.com/" target="_blank">Oualis</a> 2024</p>
    </div>
</div>
<!--**********************************
    Footer end
***********************************-->
