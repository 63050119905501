import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { empty } from 'rxjs';
@Component({
  selector: 'app-compose',
  templateUrl: './compose.component.html',
  styleUrls: ['./compose.component.css']
})
export class ComposeComponent implements OnInit {

  apiUrl = 'https://oualis.com/Platforme/api/';
email: string = '';
phone_number: string = '';
password: string = '';
rememberMe: boolean = false;
name: any;
constructor(private http: HttpClient, private router: Router) {}
ngOnInit(): void {
  this.fetchUsers();
}
role:any;
iduser:any;
onSubmit(): void {
  const body = {
    iduser: this.iduser,
    nom: this.name,
    role: this.role,
    phone: this.phone_number,
    password: this.password,
    status: 'oui'
  };

  this.http.post<any>(`${this.apiUrl}add-equipe`, body)
    .subscribe(
      response => {
        // Handle successful login, e.g., store token in local storage
        alert('Merci Ajouter avec succès');
        window.location.reload(); // Reload the page  // Reset password to an empty string
      },
      error => {
        // Handle error, e.g., show error message
        console.error('Login error:', error);
        alert('Vérifier les informations ou numéro déjà existant');
      }
    );
}
resetForm(): void {
  this.name = '';
  this.phone_number = '';
  this.password = '';
}

users45:any;
fetchUsers(): void {
  this.http.get<any[]>('https://oualis.com/Platforme/api/Allusers').subscribe(
   (response) => {
     this.users45 = response;

      console.log('Users:', this.users45); // Logging to verify data in console
   },
   (error) => {
    //  this.error = 'Error fetching users. Please try again.'; // Set error message
      console.error('Error fetching users:', error);
   }
 );
}

}
