import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-buy-order',
  templateUrl: './buy-order.component.html',
  styleUrls: ['./buy-order.component.css']
})
export class BuyOrderComponent implements OnInit {

  users: any[] = [];
  loading: boolean = false;
  error: string | null = null;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.fetchUsers();
  }
  users45:any;
  a:any='all';
  fetchUsers(): void {
    this.loading = true; // Set loading to true before making the HTTP request
    this.http.get<any[]>('https://oualis.com/Platforme/api/Allusers').subscribe(
      (response) => {
        this.users45 = response;

        this.loading = false; // Set loading to false after successful data fetch

this.a = 'erent';
        console.log('Users:', this.users); // Logging to verify data in console
      },
      (error) => {
        this.error = 'Error fetching users. Please try again.'; // Set error message
        this.loading = false; // Set loading to false on error
        console.error('Error fetching users:', error);
      }
    );
  }
}
