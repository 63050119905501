<!--**********************************
	Content body start
***********************************-->
<div class="content-body">
	<div class="container-fluid">
		<!-- Add Order -->
		<div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Bonjour, bon retour !</h4>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Souk</a></li>
                </ol>
            </div>
        </div>
		<div class="row">
			<div class="col-xl-12 col-lg-12">
				<div class="card">
          <div class="row">
            <div class="col-12" style="margin-top: 17px;
  margin-left: 17px;">
           <button style="padding: 9px 32px;
           margin-bottom: 12px;
           border: 0px;
           border-radius: 10px;
           color: white;
           background: #9e3383;" (click)="change()">Ajouter</button>
            </div>
            </div>
          <div class="card-body" *ngIf="detzils==0"   >
            <div class="table-responsive recentOrderTable">
                <table class="table verticle-middle table-responsive-md">
                    <thead>
                        <tr>
                            <th scope="col">ID.</th>
                            <th scope="col">Nom</th>

                            <!-- <th scope="col"></th>
                            <th scope="col">N°Caravane</th>
                            <th scope="col">N°Caravane</th> -->
                            <!-- <th scope="col"></th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of plannings" >
                            <td>{{item.id}}</td>
                            <td>{{item.nom}}</td>


                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
        <div class="card-body" *ngIf="detzils==1"   >
           <div class="row">
            <div class="col-12">
           <button style="padding: 9px 32px;
           margin-bottom: 12px;
           border: 0px;
           border-radius: 10px;
           color: white;
           background: #9e3383;" (click)="change()" >Retour</button>
            </div>

            <div class="col-lg-12 col-sm-12">
              <div class="row">
                <div class="col-12">
                  <h3>Ajouter Question Rapport :</h3>
                    <input type="text" name="" [(ngModel)]="Questionne" id="">
                    <button style="padding: 9px 32px;
                    margin-bottom: 12px;
                    border: 0px;
                    border-radius: 10px;
                    color: white;
                    background: #9e3383;" (click)="onSubmit()" >Ajouter</button>
                </div>
              </div>
            </div>

           </div>
      </div>
				</div>
			</div>


		</div>
	</div>
</div>
<!--**********************************
	Content body end
***********************************-->
