<div class="authincation h-100">
    <div class="container vh-100">
        <div class="row justify-content-center h-100 align-items-center">
            <div class="col-lg-6 col-md-8">
                <div class="authincation-content">
                    <div class="row no-gutters">
                        <div class="col-xl-12">
                            <div class="auth-form">
                                <div class="text-center mb-3">
                                    <a><img src="assets/logooualis.webp" alt="" style="width:50%"></a>
                                </div>
                                <h4 class="text-center mb-4">Connectez-vous à votre compte</h4>
                                <form (ngSubmit)="onSubmit()">
                                  <div class="form-group">
                                      <label class="mb-1"><strong>Email</strong></label>
                                      <input type="email" class="form-control" placeholder="Enter Your Email" [(ngModel)]="email" name="email" required>
                                  </div>
                                  <div class="form-group">
                                      <label class="mb-1"><strong>Mot de passe</strong></label>
                                      <input type="password" class="form-control" placeholder="Enter Your Password" [(ngModel)]="password" name="password" required>
                                  </div>
                                  <div class="form-group">
                                      <div class="custom-control custom-checkbox ml-1">
                                          <input type="checkbox" class="custom-control-input" id="rememberMe" [(ngModel)]="rememberMe" name="rememberMe">
                                          <label class="custom-control-label" for="rememberMe">Rappelez-vous ma préférence</label>
                                      </div>
                                  </div>
                                  <div class="text-center">
                                      <button type="submit" class="btn btn-primary btn-block">Connecter</button>
                                  </div>
                              </form>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
