<!--**********************************
	Content body start
***********************************-->
<div class="content-body">
	<div class="container-fluid">
		<!-- Add Order -->
		<div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Bonjour, bon retour !</h4>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Souk</a></li>
                </ol>
            </div>
        </div>
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
          <div class="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
            <div class="card">
              <div class="row" *ngIf="detzils==0"  >
                <div style="margin: auto;
    margin-top: auto;
  text-align: center;
  margin-top: 20px;" class="col-lg-4 col-sm-12">
                  <button (click)="getbystatus43('En Cours')"  style="padding: 10px 32px;
  border: 0px;
  border-radius: 10px;
  color: white;
  background: #9e3383;">En Cours</button>
                </div>
                <div style="margin: auto;
    margin-top: auto;
  text-align: center;
  margin-top: 20px;" class="col-lg-4 col-sm-12">
                  <button (click)="getbystatus('pas encore commencé')"  style="padding: 10px 32px;
  border: 0px;
  border-radius: 10px;
  color: white;
  background: #9e3383;">Pas encore commencé</button>
                </div>
                <div style="margin: auto;
    margin-top: auto;
  text-align: center;
  margin-top: 20px;" class="col-lg-4 col-sm-12">
                  <button (click)="getbystatus('fin')"  style="padding: 10px 32px;
  border: 0px;
  border-radius: 10px;
  color: white;
  background: #9e3383;">Fin</button>
                </div>
              </div>

              <div class="card-body" *ngIf="detzils==0"   >
                <div class="table-responsive recentOrderTable">
                    <table class="table verticle-middle table-responsive-md">
                        <thead>
                            <tr>
                                <th scope="col">ID.</th>
                                <th scope="col">Nom</th>
                                <th scope="col">Region</th>
                                <th scope="col">Nom Superviseur</th>
                                <!-- <th scope="col">N°Caravane</th> -->
                                <th scope="col">Date de Depart</th>
                                <th scope="col">Status</th>
                                <th scope="col">Date Fin</th>
                                <th scope="col">Phase Rabbatage Status</th>
                                <th scope="col">Phase Rabbatage Départ</th>
                                <th scope="col">Phase Rabbatage Fin</th>
                                <th scope="col">Phase Installation Matériel Status</th>
                                <th scope="col">Phase Installation Matériel Départ</th>
                                <th scope="col">Phase Installation Matériel Fin</th>
                                <th scope="col">Phase Rabattage Mégafone Status</th>
                                <th scope="col">Phase Rabattage Mégafone Départ</th>
                                <th scope="col">Phase Rabattage Mégafone Fin</th>
                                <th scope="col">Phase Mise en Marche Status</th>
                                <th scope="col">Phase Mise en Marche Départ</th>
                                <th scope="col">Phase Mise en Marche Fin</th>
                                <!-- <th scope="col"></th>
                                <th scope="col">N°Caravane</th>
                                <th scope="col">N°Caravane</th> -->
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody *ngIf="plannings.length > 0">
                            <tr *ngFor="let item of plannings" >
                                <td (click)="change(item.souk.id,item.id)">{{item.id}}</td>
                                <td (click)="change(item.souk.id,item.id)">{{item.souk.nom_du_souk}}</td>
                                <td>{{item.souk?.region}}</td>

                                <td><span class="badge badge-rounded badge-primary" (click)="change(item.souk.id,item.id)"> {{item.user?.name}}</span></td>
                                <!-- <td>Caravane : {{item.user.id}}</td> -->
                                <td>{{item.datedepart}}</td>
                                <td>{{item.status}}</td>
                                <td>{{item.datefin}}</td>
                                <td>{{item.phas1status}}</td>
                                <td>{{item.phas1dated}}</td>
                                <td>{{item.phas1datef}}</td>
                                <td>{{item.phas2status}}</td>
                                <td>{{item.phas2dated}}</td>
                                <td>{{item.phas2datef}}</td>
                                <td>{{item.phas3status}}</td>
                                <td>{{item.phas3dated}}</td>
                                <td>{{item.phas3datef}}</td>
                                <td>{{item.phas4status}}</td>
                                <td>{{item.phas4dated}}</td>
                                <td>{{item.phas4datef}}</td>

                                <td>
                                    <div class="dropdown dropdown-no-icon custom-dropdown mb-0"  display="dynamic" placement="bottom-right" ngbDropdown (click)="change(item.souk.id,item.id)" >
                                        <div class="btn sharp btn-primary tp-btn" ngbDropdownToggle>
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="12" cy="5" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="12" cy="19" r="2"/></g></svg>
                                        </div>

                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-body" *ngIf="detzils==1"   >
               <div class="row">
                <div class="col-12">
               <button style="padding: 9px 32px;
               margin-bottom: 12px;
               border: 0px;
               border-radius: 10px;
               color: white;
               background: #9e3383;" (click)="change()" >Retour</button>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="row listt">
                    <div class="col-12">
                      <h3>Note :</h3>
                      <p *ngFor="let item of soukNote;let i =index" >{{i+1}} -{{item.note}}</p>

                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="row listt">
                    <div class="col-12">
                      <h3>Tache :</h3>
                      <p *ngFor="let item of soukTaches;let i =index" >{{i+1}} - {{item.task}} - {{item.status}}</p>
                    </div>
                  </div>
                </div><br>
                <div class="col-lg-12 col-sm-12">
                  <div class="row">
                    <div class="col-12">
                      <h3>Info Planning :</h3><br>
                       <div class="row">
                        <div class="col-6" style="border: 1px solid;">
                          <div class="row">
                            <div class="col-lg-6 col-sm-6"  style="border-bottom: 1px solid ;margin-top:10px;padding-bottom:10px;"><th>Départ Souk KM : </th></div>
                            <div class="col-lg-6 col-sm-6"  style="border-bottom: 1px solid ;margin-top:10px;padding-bottom:10px;"><th>{{planningsStock.soukkms}}</th></div>
<hr>

                            <div class="col-lg-6 col-sm-6"  style="border-bottom: 1px solid ;margin-top:10px;padding-bottom:10px;"><th>Image Départ Souk KM : </th></div>
                            <div class="col-lg-6 col-sm-6"  style="border-bottom: 1px solid ;margin-top:10px;padding-bottom:10px;"><img [src]="'https://oualis.com/Platforme/public/storage/'+planningsStock.soukkmp" style="width: 100%;border-radius: 10px;" alt=""></div>
                            <hr>
                            <div class="col-lg-6 col-sm-6" style="border-bottom: 1px solid ;margin-top:10px;padding-bottom:10px;"><th>Fin Souk KM : </th></div>
                            <div class="col-lg-6 col-sm-6"  style="border-bottom: 1px solid ;margin-top:10px;padding-bottom:10px;"><th>{{planningsStock.soukkmr}}</th></div>
                            <hr>

                            <div class="col-lg-6 col-sm-6"  style="border-bottom: 1px solid ;margin-top:10px;padding-bottom:10px;"><th>Image Fin Souk KM : </th></div>
                            <div class="col-lg-6 col-sm-6"  style="border-bottom: 1px solid ;margin-top:10px;padding-bottom:10px;"><img [src]="'https://oualis.com/Platforme/public/storage/'+planningsStock.soukkmp2" style="width: 100%;border-radius: 10px;" alt=""></div>

                            <hr>

                            <div class="col-lg-6 col-sm-6"  style=" margin-top:10px;padding-bottom:10px;"><th>Total KM : </th></div>
                            <div class="col-lg-6 col-sm-6"  style=" margin-top:10px;padding-bottom:10px;">  <th>{{ planningsStock.soukkmr - planningsStock.soukkms }}</th></div>


                          </div>

                        </div>
                        <div class="col-6" style="border: 1px solid;">
                          <div class="row">
                            <div class="col-lg-6 col-sm-6"  style="border-bottom: 1px solid ;margin-top:10px;padding-bottom:10px;"><th>Départ Phase Rabattage KM : </th></div>
                            <div class="col-lg-6 col-sm-6"  style="border-bottom: 1px solid ;margin-top:10px;padding-bottom:10px;"><th>{{planningsStock.phase1kms}}</th></div>
<hr>

                            <div class="col-lg-6 col-sm-6"  style="border-bottom: 1px solid ;margin-top:10px;padding-bottom:10px;"><th>Image Départ Phase Rabattage KM : </th></div>
                            <div class="col-lg-6 col-sm-6"  style="border-bottom: 1px solid ;margin-top:10px;padding-bottom:10px;"><img [src]="'https://oualis.com/Platforme/public/storage/'+planningsStock.phase1kmp" style="width: 100%;border-radius: 10px;" alt=""></div>
                            <hr>
                            <div class="col-lg-6 col-sm-6" style="border-bottom: 1px solid ;margin-top:10px;padding-bottom:10px;"><th>Fin Phase Rabattage KM : </th></div>
                            <div class="col-lg-6 col-sm-6"  style="border-bottom: 1px solid ;margin-top:10px;padding-bottom:10px;"><th>{{planningsStock.phase1kmr}}</th></div>
                            <hr>

                            <div class="col-lg-6 col-sm-6"  style="border-bottom: 1px solid ;margin-top:10px;padding-bottom:10px;"><th>Image Fin Phase Rabattage KM : </th></div>
                            <div class="col-lg-6 col-sm-6"  style="border-bottom: 1px solid ;margin-top:10px;padding-bottom:10px;"><img [src]="'https://oualis.com/Platforme/public/storage/'+planningsStock.phase1kmp2" style="width: 100%;border-radius: 10px;" alt=""></div>



                            <hr>

                            <div class="col-lg-6 col-sm-6"  style="margin-top:10px;padding-bottom:10px;"><th>Total KM : </th></div>
                            <div class="col-lg-6 col-sm-6"  style="margin-top:10px;padding-bottom:10px;">  <th>{{ planningsStock.phase1kmr - planningsStock.phase1kms }}</th></div>



                          </div>


                        </div>
                        <div class="col-6">
                          <h3>Réponse Rapport :</h3>
                          <div class="row" *ngFor="let item of rrapport" >
                            <div class="col-6" style="border:1px solid">{{item.nom}} : </div>
                            <div class="col-6" style="border:1px solid">{{item.reponse}}</div>
                          </div>
                            </div>
                            <div class="col-6">
                              <h3>Réponse Souk :</h3>
                   <div class="row" *ngFor="let item of rsouk" >
                    <div class="col-6" style="border:1px solid">{{item.nom}} :</div>
                    <div class="col-6" style="border:1px solid">{{item.reponse}}</div>
                  </div>
                                </div>

                                <div class="col-12">
                                  <h3>List Media : </h3>
                                  <div class="row">
                                    <div class="col-4" *ngFor="let item of users45">
                                      <div *ngIf="item.type == 'image'">
                                        <img style="width: 20vw; height: 20vw; object-fit: cover; border-radius: 17px;" [src]="'https://oualis.com/Platforme/public/storage/' + item.filepath" alt="">
                                      </div>
                                      <div *ngIf="item.type == 'video'">
                                        <video style="width: 20vw; height: 20vw; object-fit: cover; border-radius: 17px;" controls="true" [src]="'https://oualis.com/Platforme/public/storage/' + item.filepath"></video>
                                      </div>
                                      <div *ngIf="item.type == 'audio'">
                                        <audio controls [src]="'https://oualis.com/Platforme/public/storage/' + item.filepath"></audio>
                                      </div>

                                      <div style="margin-top: 10px;">
                                        <div class="row">
                                          <label class="col-6" style="text-align: center;">
                                            <input type="radio" name="status-{{ item.filepath }}" (change)="toggleStatus(item, 'no')" [checked]="item.status === 'no'"> No
                                          </label>
                                          <label class="col-6" style="text-align: center;">
                                            <input type="radio" name="status-{{ item.filepath }}" (change)="toggleStatus(item, 'ok')" [checked]="item.status === 'ok'"> Ok
                                          </label>
                                        </div>
                                        <br>
                                        <div style="text-align: center;">
                                          <button (click)="downloadFile(item.id)" style="padding: 10px;
                                          background-color: #9e3383;
                                          color: white;
                                          border: none;
                                          border-radius: 5px;
                                          width: 77%;">Download</button>
                                        </div>
                                      </div>
                                    </div>



                                  </div>

                                </div>


                       </div>
                    </div>
                  </div>
                </div>

               </div>
          </div>
            </div>
        </div>
				</div>
				<!-- /# card -->
			</div>
		</div>
	</div>
</div>
<!--**********************************
	Content body end
***********************************-->
