
<!--**********************************
	Content body start
***********************************-->
<div class="content-body">
	<div class="container-fluid">
		<div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                  <h4>Bonjour, bon retour !</h4>
                                 </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Stock Detail</a></li>
                    <!-- <li class="breadcrumb-item active"><a href="javascript:void(0)">Superviseur</a></li> -->
                </ol>
            </div>
        </div>
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<div class="table-responsive">
							<table class="table table-responsive-lg mb-0 table-striped">
								<thead>
									<tr>
										<th  >
											ID
										</th>
										<th>Id Stock</th>
										<th>Nom Superviseur</th>
										<th>Image</th>
 										<th>Qty</th>
										<th>Date de Création</th>

										<!-- <th>Joined</th> -->
										<th></th>
									</tr>
								</thead>
                <tbody id="customers">
                  <tr class="btn-reveal-trigger" *ngFor="let item of users45; let i = index">
                    <td class="py-2 pl-5 wspace-no">{{ item.id }}</td>
                    <td class="py-2 pl-5 wspace-no">{{ item.idstock }}</td>
                    <td class="py-2"><a>{{ item.user.name }}</a></td>
                    <td class="py-2">
                      <a>
                        <img [src]="'https://oualis.com/Platforme/public/storage/' + item.image" alt="" style="width:20%" *ngIf="item.image">
                      </a>
                    </td>
                    <td class="py-2"><a>{{ item.qty }}</a></td>
                    <td class="py-2"><a>{{ item.created_at | slice: 0:10 }}</a></td>
                  </tr>
                </tbody>

							</table>


						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!--**********************************
	Content body end
***********************************-->
<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary">
  Launch demo modal
</button> -->

<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        ...
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>
