
<!--**********************************
	Content body start
***********************************-->
<div class="content-body">
	<div class="container-fluid">
		<div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                  <h4>Bonjour, bon retour !</h4>
                                 </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Voiture</a></li>
                    <!-- <li class="breadcrumb-item active"><a href="javascript:void(0)">Superviseur</a></li> -->
                </ol>
            </div>
        </div>
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body" *ngIf="imageshow == 1">
						<div class="table-responsive">
							<table class="table table-responsive-lg mb-0 table-striped">
								<thead>
									<tr>
										<th  >
											ID
										</th>
										<th>Id Superviseur</th>
										<th>Nom Superviseur</th>
										<th>Immatriculation</th>
										<th>KM</th>
										<th>Model</th>
										<th>Marque</th>

										<!-- <th>Joined</th> -->
										<th></th>
									</tr>
								</thead>
								<tbody id="customers">
									<tr class="btn-reveal-trigger" *ngFor="let item of users45" >

                    <td class="py-2 pl-5 wspace-no" (click)="fetchImage(item.id)">{{item.id}}</td>
                    <td class="py-2 pl-5 wspace-no" (click)="fetchImage(item.id)">{{item.iduser}}</td>
                    <td class="py-2 pl-5 wspace-no" (click)="fetchImage(item.id)">{{item.user.name}}</td>

										<td class="py-2"><a  (click)="fetchImage(item.id)">{{item.immatriculation}}</a></td>
										<td class="py-2"><a (click)="fetchImage(item.id)" >{{item.km}}</a></td>
										<td class="py-2"><a  (click)="fetchImage(item.id)">{{item.model}}</a></td>
										<td class="py-2"><a  (click)="fetchImage(item.id)">{{item.marque}}</a></td>

									</tr>

								</tbody>
							</table>


						</div>
					</div>
          <div class="card-body" *ngIf="imageshow == 2">
            <button (click)="change()" style="padding: 10px 20px;
            border: 0px;
            border-radius: 10px;
            color: white;
            background: #9e3383;">Retour</button>
						<div class="table-responsive">
					 <div class="row">
            <div class="col-4" *ngFor="let item of image">
              <img [src]="item.url" style="width: 100%;
  margin-top: 10px;">
            </div>
           </div>

           <div class="row" *ngFor="let item of depanse"  style="margin-top: 20px; padding: 30px; border: 1px solid black; width: 100%; margin-left: 0px; display: flex; justify-content: center; align-items: center; text-align: center;">
            <div class="col-4" style="display: flex; flex-direction: column; justify-content: center;">
              <h5>Type : {{item.type}}</h5>
            </div>
            <div class="col-4" style="display: flex; flex-direction: column; justify-content: center;">
              <h5>Montant : {{item.prix}}</h5>
            </div>
            <div class="col-4" style="display: flex; flex-direction: column; justify-content: center;">
              <h5>Image : <a [href]="'https://oualis.com/Platforme/public/storage/'+item.photo" target="_blank">Fichier</a> </h5>
            </div>
          </div>
<!--
          <div class="row" style="margin-top: 20px; padding: 30px; border: 1px solid black; width: 100%; margin-left: 0px; display: flex; justify-content: center; align-items: center; text-align: center;">
            <div class="col-4" style="display: flex; flex-direction: column; justify-content: center;">
              <h5>Type : Gasoial</h5>
            </div>
            <div class="col-4" style="display: flex; flex-direction: column; justify-content: center;">
              <h5>Montant : Gasoial</h5>
            </div>
            <div class="col-4" style="display: flex; flex-direction: column; justify-content: center;">
              <h5>Image : <a [href]="">Fichier</a> </h5>
            </div>
          </div> -->


						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!--**********************************
	Content body end
***********************************-->
<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary">
  Launch demo modal
</button> -->

<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        ...
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>
