import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
 import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-form-validate',
  templateUrl: './form-validate.component.html',
  styleUrls: ['./form-validate.component.css']
})
export class FormValidateComponent implements OnInit {



  constructor(private http: HttpClient) {}


  plannings:any;

  ngOnInit(): void {
    this.http.get<any>(`https://oualis.com/Platforme/api/qrapport`).subscribe(
      (response) => {
        this.plannings = response;
       },
      (error) => {
        console.error('Error fetching souk count:', error);
      }
    );
  }
detzils:any=0;
planningsStock:any;
rrapport:any;
rsouk:any;
Questionne:any;
change(){
  if(this.detzils == 0)
    {
      this.detzils = 1;


    }
    else{
      this.detzils = 0;
    }
}
soukNote:any;
getsouck(idsouk:any){
  this.http.get<any>(`https://oualis.com/Platforme/api/notes/souk/`+idsouk).subscribe(
    (response) => {
      this.soukNote = response;
     },
    (error) => {
      console.error('Error fetching souk count:', error);
    }
  );

}

soukTaches:any;
getTaches(idsouk:any){
  this.http.get<any>(`https://oualis.com/Platforme/api/checklists/souk/`+idsouk).subscribe(
    (response) => {
      this.soukTaches = response;
     },
    (error) => {
      console.error('Error fetching souk count:', error);
    }
  );

}
apiUrl = 'https://oualis.com/Platforme/api/';
onSubmit(): void {
  const body = {
     nom: this.Questionne,

    type: 'ouinon'
  };

  this.http.post<any>(`${this.apiUrl}qrapport`, body)
    .subscribe(
      response => {
        // Handle successful login, e.g., store token in local storage
        alert('Merci Ajouter avec succès');
        window.location.reload(); // Reload the page  // Reset password to an empty string
      },
      error => {
        // Handle error, e.g., show error message
        console.error('Login error:', error);
        alert('Vérifier les informations ou numéro déjà existant');
      }
    );
}






}

