<!--**********************************
	Content body start
***********************************-->
<div class="content-body">
	<div class="container-fluid">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">App</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Profile</a></li>
                </ol>
            </div>
        </div>
		<!-- row -->
		<div class="row">
			<div class="col-lg-12">
				<div class="profile card card-body px-3 pt-3 pb-0">
					<div class="profile-head">
						<div class="photo-content">
							<div class="cover-photo"></div>
						</div>
						<div class="profile-info">
							<div class="profile-photo">
								<img src="assets/images/profile/profile.png" class="img-fluid rounded-circle" alt="">
							</div>
							<div class="profile-details">
								<div class="profile-name px-3 pt-2">
									<h4 class="text-primary mb-0">Mitchell C. Shay</h4>
									<p>UX / UI Designer</p>
								</div>
								<div class="profile-email px-2 pt-2">
									<h4 class="text-muted mb-0">info@example.com</h4>
									<p>Email</p>
								</div>
								<div class="dropdown dropdown-no-icon ml-auto" ngbDropdown>
									<a href="javascript:void(0);" class="btn btn-primary light sharp" ngbDropdownToggle aria-expanded="true"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg></a>
									<ul class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
										<li class="dropdown-item"><i class="fa fa-user-circle text-primary mr-2"></i> View profile</li>
										<li class="dropdown-item"><i class="fa fa-users text-primary mr-2"></i> Add to close friends</li>
										<li class="dropdown-item"><i class="fa fa-plus text-primary mr-2"></i> Add to group</li>
										<li class="dropdown-item"><i class="fa fa-ban text-primary mr-2"></i> Block</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xl-4">
				<div class="row">
					<div class="col-xl-12">
						<app-user-statistics></app-user-statistics>
					</div>
					<div class="col-xl-12">
						<app-highlights></app-highlights>
					</div>
					<div class="col-xl-12">
						<app-interest></app-interest>
					</div>
					<div class="col-xl-12">
						<app-latest-news  [data]="news"></app-latest-news>
					</div>
				</div>
			</div>
			<div class="col-xl-8">
				<div class="card">
					<div class="card-body">
						<div class="profile-tab">
							<div class="custom-tab-1">
								<ul class="nav nav-tabs" ngbNav #nav="ngbNav" >
									<li class="nav-item" ngbNavItem="posts"><a href="#my-posts" data-toggle="tab" class="nav-link" ngbNavLink>Posts</a>
										<ng-template ngbNavContent>
											<div id="my-posts" class="tab-pane fade active show">
												<div class="my-post-content pt-3">
													<div class="post-input">
														<textarea name="textarea" id="textarea" cols="30" rows="5" class="form-control bg-transparent" placeholder="Please type what you want...."></textarea> 
														<a href="javascript:void()" class="btn btn-primary light px-3 mr-2" (click)="open(linkModal)"><i class="fa fa-link"></i> </a>
														<!-- Modal -->
														<ng-template #linkModal let-modal>
															<div class="modal-header">
																<h5 class="modal-title">Social Links</h5>
																<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div class="modal-body post-input mb-0">
																<a class="btn-social facebook" href="javascript:void(0)"><i class="fa fa-facebook"></i></a>
																<a class="btn-social google-plus" href="javascript:void(0)"><i class="fa fa-google-plus"></i></a>
																<a class="btn-social linkedin" href="javascript:void(0)" ><i class="fa fa-linkedin"></i></a>
																<a class="btn-social instagram" href="javascript:void(0)"><i class="fa fa-instagram"></i></a>
																<a class="btn-social twitter" href="javascript:void(0)"><i class="fa fa-twitter"></i></a>
																<a class="btn-social youtube" href="javascript:void(0)"><i class="fa fa-youtube"></i></a>
																<a class="btn-social whatsapp" href="javascript:void(0)"><i class="fa fa-whatsapp"></i></a>
															</div>
														</ng-template>
														<a href="javascript:void()" class="btn btn-primary light mr-2 px-3" (click)="open(cameraModal)"><i class="fa fa-camera"></i> </a>
														<!-- Modal -->
														<ng-template #cameraModal let-modal>
															<div class="modal-header">
																<h5 class="modal-title">Upload images</h5>
																<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div class="modal-body">
																<div class="input-group mb-3">
																	<div class="input-group-prepend">
																		<span class="input-group-text">Upload</span>
																	</div>
																	<div class="custom-file">
																		<input type="file" class="custom-file-input">
																		<label class="custom-file-label">Choose file</label>
																	</div>
																</div>
															</div>
														</ng-template>
														<a href="javascript:void()" class="btn btn-primary" (click)="open(postModal)">Post</a>
														<!-- Modal -->
														<ng-template #postModal let-modal>
															<div class="modal-header">
																<h5 class="modal-title">Post</h5>
																<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div class="modal-body">
																<textarea name="textarea" id="textarea2" cols="30" rows="5" class="form-control bg-transparent mb-3" placeholder="Please type what you want...."></textarea>
																<a class="btn btn-primary btn-rounded" href="javascript:void(0)">Post</a>																		 
															</div>
														</ng-template>
													</div>
													<div class="profile-uoloaded-post border-bottom-1 pb-5">
														<img src="assets/images/profile/8.jpg" alt="" class="img-fluid">
														<a class="post-title" [routerLink]="['/admin/post-details']"><h3 class="text-black">Collection of textile samples lay spread</h3></a>
														<p>A wonderful serenity has take possession of my entire soul like these sweet morning of spare which enjoy whole heart.A wonderful serenity has take possession of my entire soul like these sweet morning
														of spare which enjoy whole heart.</p>
														<button class="btn btn-primary mr-2"><span class="mr-2"><i
														class="fa fa-heart"></i></span>Like</button>
														<button class="btn btn-secondary" (click)="open(replyModal)"><span class="mr-2"><i
														class="fa fa-reply"></i></span>Reply</button>
													</div>
													<div class="profile-uoloaded-post border-bottom-1 pb-5">
														<img src="assets/images/profile/9.jpg" alt="" class="img-fluid">
														<a class="post-title" [routerLink]="['/admin/post-details']"><h3 class="text-black">Collection of textile samples lay spread</h3></a>
														<p>A wonderful serenity has take possession of my entire soul like these sweet morning of spare which enjoy whole heart.A wonderful serenity has take possession of my entire soul like these sweet morning
														of spare which enjoy whole heart.</p>
														<button class="btn btn-primary mr-2"><span class="mr-2"><i
														class="fa fa-heart"></i></span>Like</button>
														<button class="btn btn-secondary"  (click)="open(replyModal)"><span class="mr-2"><i
														class="fa fa-reply"></i></span>Reply</button>
													</div>
													<div class="profile-uoloaded-post pb-3">
														<img src="assets/images/profile/8.jpg" alt="" class="img-fluid">
														<a class="post-title" [routerLink]="['/admin/post-details']"><h3 class="text-black">Collection of textile samples lay spread</h3></a>
														<p>A wonderful serenity has take possession of my entire soul like these sweet morning of spare which enjoy whole heart.A wonderful serenity has take possession of my entire soul like these sweet morning
														of spare which enjoy whole heart.</p>
														<button class="btn btn-primary mr-2"><span class="mr-2"><i
														class="fa fa-heart"></i></span>Like</button>
														<button class="btn btn-secondary"  (click)="open(replyModal)"><span class="mr-2"><i
														class="fa fa-reply"></i></span>Reply</button>
													</div>
												</div>
											</div>
										</ng-template>
										
									</li>
									<li class="nav-item" ngbNavItem="about-me"><a href="#about-me" data-toggle="tab" class="nav-link" ngbNavLink>About Me</a>
										<ng-template ngbNavContent>
											<div id="about-me" class="tab-pane">
												<div class="profile-about-me">
													<div class="pt-4 border-bottom-1 pb-3">
														<h4 class="text-primary">About Me</h4>
														<p class="mb-2">A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence was created for the bliss of souls like mine.I am so happy, my dear friend, so absorbed in the exquisite sense of mere tranquil existence, that I neglect my talents.</p>
														<p>A collection of textile samples lay spread out on the table - Samsa was a travelling salesman - and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame.</p>
													</div>
												</div>
												<div class="profile-skills mb-5">
													<h4 class="text-primary mb-2">Skills</h4>
													<a href="javascript:void()" class="btn btn-primary light btn-xs mb-1 mr-1">Admin</a>
													<a href="javascript:void()" class="btn btn-primary light btn-xs mb-1 mr-1">Dashboard</a>
													<a href="javascript:void()" class="btn btn-primary light btn-xs mb-1 mr-1">Photoshop</a>
													<a href="javascript:void()" class="btn btn-primary light btn-xs mb-1 mr-1">Bootstrap</a>
													<a href="javascript:void()" class="btn btn-primary light btn-xs mb-1 mr-1">Responsive</a>
													<a href="javascript:void()" class="btn btn-primary light btn-xs mb-1 mr-1">Crypto</a>
												</div>
												<div class="profile-lang  mb-5">
													<h4 class="text-primary mb-2">Language</h4>
													<a href="javascript:void()" class="text-muted pr-3 f-s-16"><i class="flag-icon flag-icon-us"></i> English</a> 
													<a href="javascript:void()" class="text-muted pr-3 f-s-16"><i class="flag-icon flag-icon-fr"></i> French</a>
													<a href="javascript:void()" class="text-muted pr-3 f-s-16"><i class="flag-icon flag-icon-bd"></i> Bangla</a>
												</div>
												<div class="profile-personal-info">
													<h4 class="text-primary mb-4">Personal Information</h4>
													<div class="row mb-2">
														<div class="col-sm-3 col-5">
															<h5 class="f-w-500">Name <span class="pull-right">:</span>
															</h5>
														</div>
														<div class="col-sm-9 col-7"><span>Mitchell C.Shay</span>
														</div>
													</div>
													<div class="row mb-2">
														<div class="col-sm-3 col-5">
															<h5 class="f-w-500">Email <span class="pull-right">:</span>
															</h5>
														</div>
														<div class="col-sm-9 col-7"><span>example@examplel.com</span>
														</div>
													</div>
													<div class="row mb-2">
														<div class="col-sm-3 col-5">
															<h5 class="f-w-500">Availability <span class="pull-right">:</span></h5>
														</div>
														<div class="col-sm-9 col-7"><span>Full Time (Free Lancer)</span>
														</div>
													</div>
													<div class="row mb-2">
														<div class="col-sm-3 col-5">
															<h5 class="f-w-500">Age <span class="pull-right">:</span>
															</h5>
														</div>
														<div class="col-sm-9 col-7"><span>27</span>
														</div>
													</div>
													<div class="row mb-2">
														<div class="col-sm-3 col-5">
															<h5 class="f-w-500">Location <span class="pull-right">:</span></h5>
														</div>
														<div class="col-sm-9 col-7"><span>Rosemont Avenue Melbourne,
														Florida</span>
														</div>
													</div>
													<div class="row mb-2">
														<div class="col-sm-3 col-5">
															<h5 class="f-w-500">Year Experience <span class="pull-right">:</span></h5>
														</div>
														<div class="col-sm-9 col-7"><span>07 Year Experiences</span>
														</div>
													</div>
												</div>
											</div>
										</ng-template>
										
									</li>
									<li class="nav-item" ngbNavItem="profile-settings"><a href="#profile-settings" data-toggle="tab" class="nav-link" ngbNavLink>Setting</a>
										<ng-template ngbNavContent>
											<div id="profile-settings" class="tab-pane">
                                                <div class="pt-3">
                                                    <div class="settings-form">
                                                        <h4 class="text-primary">Account Setting</h4>
                                                        <form>
                                                            <div class="form-row">
                                                                <div class="form-group col-md-6">
                                                                    <label>Email</label>
                                                                    <input type="email" placeholder="Email" class="form-control">
																</div>
                                                                <div class="form-group col-md-6">
                                                                    <label>Password</label>
                                                                    <input type="password" placeholder="Password" class="form-control">
																</div>
															</div>
                                                            <div class="form-group">
                                                                <label>Address</label>
                                                                <input type="text" placeholder="1234 Main St" class="form-control">
															</div>
                                                            <div class="form-group">
                                                                <label>Address 2</label>
                                                                <input type="text" placeholder="Apartment, studio, or floor" class="form-control">
															</div>
                                                            <div class="form-row">
                                                                <div class="form-group col-md-6">
                                                                    <label>City</label>
                                                                    <input type="text" class="form-control">
																</div>
                                                                <div class="form-group col-md-4">
                                                                    <label>State</label>
                                                                    <select class="form-control default-select" id="inputState">
                                                                        <option selected="">Choose...</option>
                                                                        <option>Option 1</option>
                                                                        <option>Option 2</option>
                                                                        <option>Option 3</option>
																	</select>
																</div>
                                                                <div class="form-group col-md-2">
                                                                    <label>Zip</label>
                                                                    <input type="text" class="form-control">
																</div>
															</div>
                                                            <div class="form-group">
                                                                <div class="custom-control custom-checkbox">
																	<input type="checkbox" class="custom-control-input" id="gridCheck">
																	<label class="custom-control-label" for="gridCheck"> Check me out</label>
																</div>
															</div>
                                                            <button class="btn btn-primary" type="submit">Sign
															in</button>
														</form>
													</div>
												</div>
											</div>
										</ng-template>
										
										
									</li>
								</ul>
								
								<div [ngbNavOutlet]="nav" class=""></div>
							</div>
							<!-- Modal -->
							<ng-template #replyModal let-modal>
								
								<div class="modal-header">
									<h4 class="modal-title" id="modal-basic-title">Post Reply</h4>
									<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div class="modal-body">
									<form>
										<div class="form-group">
											<textarea class="form-control" rows="4">Message</textarea>
										</div>
										<div class="form-group">
											<button type="button" class="btn btn-primary">Reply</button>
										</div>
									</form>
								</div>
							</ng-template>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!--**********************************
	Content body end
***********************************-->