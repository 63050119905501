
<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <!-- row -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-3 col-sm-6">
                <app-graph1></app-graph1>
            </div>
            <div class="col-xl-3 col-sm-6">
                <app-graph2></app-graph2>
            </div>
            <div class="col-xl-3 col-sm-6">
                <app-graph3></app-graph3>
            </div>
            <div class="col-xl-3 col-sm-6">
                <app-graph4></app-graph4>
            </div>
            <div class="col-xl-12 col-xxl-12">
              <app-buy-order></app-buy-order>
            </div>
            <!-- <div class="col-xl-6 col-xxl-12">
                <app-graph-crypto-statistics></app-graph-crypto-statistics>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="row">
                    <div class="col-xl-12">
                        <app-quick-transfer [data]="quickTransfer"></app-quick-transfer>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-xl-6 col-xxl-12">
                <div class="row">
                    <div class="col-sm-6">
                        <app-sell-order></app-sell-order>
                    </div>
                    <div class="col-sm-6">
                        <app-buy-order></app-buy-order>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->
