<div class="card">
    <div class="card-header border-0 pb-0">
        <h4 class="mb-0 text-black fs-20">List Séperviseur</h4>

    </div>
    <div class="card-body p-3">

        <div class="table-responsive">
            <table class="table text-center bg-warning-hover tr-rounded">
                <thead>
                    <tr>
                        <th class="text-center">Nom</th>
                        <th class="text-center">Numéro Télephone </th>
                        <th class="text-center">Role</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of users45" >
                        <td  >{{item.name}}</td>
                        <td>{{item.phone_number}}</td>
                        <td  >{{item.Role}}</td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>

</div>
