
<!--**********************************
	Content body start
***********************************-->
<div class="content-body">
	<div class="container-fluid">
		<div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                  <h4>Bonjour, bon retour !</h4>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Gestion de Stock</a></li>
                 </ol>
            </div>
        </div>
		<div class="row">
			<div class="col-xl-12">
				<div class="card">
					<div class="card-body">
						<div class="row">
					 	<div class="col-lg-12 col-md-12 order-md-1">
								<h4 class="mb-3">Ajouter Stock</h4>
                <div class="mb-3">
                  <label for="country">Sélectionner Superviseur</label>
                  <select class="d-block w-100 default-select form-control" id="country" required="" [(ngModel)]="iduser">
                    <option value="">Choose...</option>
                    <option *ngFor="let item of users45" [value]="item.id">{{item.name}}</option>
                  </select>
                </div>
 									<div class="row">
										<div class="col-md-6 mb-3">
											<label for="firstName">Image</label>
											<input type="text" class="form-control" id="firstName" placeholder="Ajouter URL"  required="" [(ngModel)]="Image">
											<!-- <div class="invalid-feedback">
												Valid first name is required.
											</div> -->
										</div>
										<div class="col-md-6 mb-3">
											<label for="lastName">Name</label>
											<input type="text" class="form-control" id="lastName" placeholder="" value="" required="" [(ngModel)]="name">
										</div>
                    <div class="col-md-6 mb-3">
											<label for="lastName">Qty</label>
											<input type="text" class="form-control" id="lastName" placeholder="" value="" required="" [(ngModel)]="Qty">
										</div>
                    <div class="col-md-6 mb-3">
											<label for="lastName">Date Stock</label>
											<input type="date" class="form-control" id="lastName" placeholder="" value="" required="" [(ngModel)]="datestock">
										</div>
									</div>
		              <hr class="mb-4">
									<button class="btn btn-primary btn-lg btn-block" type="submit" (click)="onSubmit()">Ajouter</button>
 							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!--**********************************
	Content body end
***********************************-->
