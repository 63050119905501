<div class="nav-header">
    <a [routerLink]="['/admin/index']" class="brand-logo">
      <img src="assets/logooualis.webp" style="width:45%" alt="">
    </a>

    <div class="nav-control">
        <div [ngClass]="{'is-active': hamburgerClass, '': !hamburgerClass}" class="hamburger" (click)="toggleHamburgerClass()">
            <span class="line"></span><span class="line"></span><span class="line"></span>
        </div>
    </div>
</div>
